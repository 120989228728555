//提示：为避免命名冲突 name定义时建议以search_ || s_ 标识

import Layout from '@/layout'
const router = [{
    path: '/search',
    name: 'search',
    component: Layout,
    meta: {
        title: '专利检索',
        hidden:true
    },
    redirect: {
        name: 's_quickSearch'
    },
    children: [{
        path: 'quicksearch',
        name: 's_quickSearch',
        meta: {
            title: '快速检索',
            icon: "alert"

        },
        component: () => import('@/views/search/search/quick/')
    },
    {
        path: 'tablesearch',
        name: 's_tableSearch',
        meta: {
            title: '表格检索',
            icon: "database"
        },
        component: () => import('@/views/search/search/table/')
    },
    {
        path: 'aisearch',
        name: 's_aiSearch',
        meta: {
            title: '智能检索',
            icon: "monitor"
        },
        component: () => import('@/views/search/search/ai/')
    },
    {
        path: 'numbersearch',
        name: 's_numberSearch',
        meta: {
            title: '号码检索',
            icon: "number"
        },
        component: () => import('@/views/search/search/number/')
    },{
        path: 'searchhistory',
        name: 's_searchhistory',
        meta: {
            title: '检索历史',
            icon: "history"
        },
        component: () => import('@/views/search/search/mysearch/searchhistory')
    },{
        path: 'savedsearchlist',
        name: 's_savedsearchlist',
        meta: {
            title: '已保存检索式',
            icon: "save"
        },
        component: () => import('@/views/search/search/mysearch/savedsearchlist')
    },{
        path: 'searchresult',
        name: 's_searchresult',
        meta: {
            title: '检索结果',
            hidden: true
        },
        component: () => import('@/views/search/search/searchresult/')
    }]
}]

export default router





