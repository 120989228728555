<template>
  <div class="app-navbar-right">
    <a-dropdown class="app-header-trigger">
      <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
        <a-icon type="user" style="font-size:18px;" /> 管理员 <a-icon type="down" />
      </a>
      <a-menu slot="overlay">
        <!-- <a-menu-item key="0">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.alipay.com/"
            >修改密码</a
          >
        </a-menu-item>
        <a-menu-item key="1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.alipay.com/"
            >个人信息</a
          >
        </a-menu-item>
        <a-menu-divider /> -->
        <a-menu-item key="2"> 安全退出 </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>