const state = {
    collapseSidebar: false
}

const mutations = {
    COLLAPSE_SIDEBAR: (state)=>{
        state.collapseSidebar = !state.collapseSidebar
    }
}

const actions = {
    collapseSidebar({ commit }) {
        commit('COLLAPSE_SIDEBAR')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}