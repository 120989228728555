<template>
    <a-tabs :activeKey="current" class="app-menubar" @change="handleTo" :tabBarGutter="0">
        <template v-for="item in menu">
            <a-tab-pane :tab="item.meta.title" :key="item.name" v-if="!item.meta.hidden"></a-tab-pane>
        </template>
    </a-tabs>
</template>

<script>
export default {
  props: {
      menu: {
          type: Array,
          default: ()=>[]
      }
  },
  data(){
      return{
          current: ''
      }
  },
  watch: {
      $route(to, from){
          this.getCurrent();
      }
  },
  created(){
      this.getCurrent();
  },
  methods: {
      getCurrent(){
          this.current = this.$route.matched[0].name;
      },
      handleTo(item){
          this.$router.push({ name: item });
      }
  }
};
</script>