<template>
    <div class="app-sidebar">
        <div class="app-sidebar-navbar">
            <navbar :menu="menu"/>
        </div>
        <div class="app-sidebar-collapse" @click="handleCollapse">
            <a-icon :type="collapseSidebar?'menu-unfold':'menu-fold'" />
        </div>
    </div> 
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        menu: {
            type: Array,
            default: ()=>[]
        }
    },
    computed: {
        ...mapGetters([
            'collapseSidebar'
        ]),
    },
    methods: {
        handleCollapse(){
            this.$store.dispatch('app/collapseSidebar')
        }
    }
}
</script>