//提示：为避免命名冲突 name定义时建议以result_ || r_ 标识

import Layout from '@/layout'
import PageLayout from '@/layout/PageLayout'
const router = [{
    path: '/result',
    name: 'result',
    component: Layout,
    meta: {
        title: '成果智能化分析'
    },
    redirect: {
        name: 'r_analysis'
    },
    children: [{
        path: 'analysis',
        name: 'r_analysis',
        meta: {
            title: '科技创新成果智能化分析',
            icon: "alert"
        },
        component: PageLayout,
        redirect: {name: 'r_analysis_manage'},
        children:[{
            path: 'manage',
            name: 'r_analysis_manage',
            meta: {
                title: '成果库管理'
            },
            component: ()=>import('@/views/result/analysis/manage')
        },{
            path: 'display',
            name: 'r_analysis_display',
            meta: {
                title: '成果多维度呈现'
            },
            component: ()=>import('@/views/result/analysis/display')
        },{
            path: 'analysis',
            name: 'r_analysis_analysis',
            meta: {
                title: '成果多尺度分析'
            },
            component: ()=>import('@/views/result/analysis/analysis')
        }] 
    },{
        path: 'professor',
        name: 'r_professor',
        meta: {
            title: '专家自动匹配工具',
            icon: 'appstore'
        },
        component: PageLayout,
        redirect: {name: 'r_professor_info'},
        children:[{
            path: 'info',
            name: 'r_professor_info',
            meta: {
                title: '信息自动提取'
            },
            redirect: {name: 'r_professor_info_entering'},
            component: PageLayout,
            children: [{
                path: 'entering',
                name: 'r_professor_info_entering',
                meta: {
                    title: '目标信息关键词录入'
                },
                component: ()=>import('@/views/result/professor/info/entering')
            },{
                path: 'link',
                name: 'r_professor_info_link',
                meta: {
                    title: '目标地址集构建'
                },
                component: ()=>import('@/views/result/professor/info/link')
            },{
                path: 'extract',
                name: 'r_professor_info_extract',
                meta: {
                    title: '信息提取'
                },
                component: ()=>import('@/views/result/professor/info/extract')
            }]
           
        },{
            path: 'database',
            name: 'r_professor_database',
            meta: {
                title: '创建自主学习库专家'
            },
            redirect: {name: 'r_professor_database_update'},
            component: PageLayout,
            children: [{
                path: 'update',
                name: 'r_professor_database_update',
                meta: {
                    title: '专家信息更新'
                },
                component: ()=>import('@/views/result/professor/database/update')
            },{
                path: 'increase',
                name: 'r_professor_database_increase',
                meta: {
                    title: '增补新晋专家'
                },
                component: ()=>import('@/views/result/professor/database/increase')
            }]
        },{
            path: 'match',
            name: 'r_professor_match',
            meta: {
                title: '遴选高匹配度专家'
            },
            redirect: {name: 'r_professor_match_feature'},
            component: PageLayout,
            children: [{
                path: 'feature',
                name: 'r_professor_match_feature',
                meta: {
                    title: '特征匹配'
                },
                component: ()=>import('@/views/result/professor/match/feature')
            }]
        }]
    },{
        path: 'trend',
        name: 'r_trend',
        meta: {
            title: '成果趋势分析',
            icon: "alert"
        },
        component: PageLayout,
        redirect: {name: 'r_trend_relevance'},
        children:[{
            path: 'relevance',
            name: 'r_trend_relevance',
            meta: {
                title: '成果关联分析'
            },
            component: ()=>import('@/views/result/trend/relevance')
        },{
            path: 'extension',
            name: 'r_trend_extension',
            meta: {
                title: '目标成果跨学科延展分析'
            },
            component: ()=>import('@/views/result/trend/extension')
        },{
            path: 'policy',
            name: 'r_trend_policy',
            meta: {
                title: '政策性动态信息收集'
            },
            component: ()=>import('@/views/result/trend/policy')
        },{
            path: 'explore',
            name: 'r_trend_explore',
            meta: {
                title: '趋势探索'
            },
            component: ()=>import('@/views/result/trend/explore')
        }] 
    }]
}]

export default router





