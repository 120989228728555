<template>
  <div>
    欢迎访问！
  </div>
</template>

<script>
export default {
}
</script>
