<template>
  <a-menu
    class="app-navbar"
    :selectedKeys="current"
    :mode="mode"
    :theme="theme"
    :openKeys.sync="current"
    :inline-collapsed="collapse"
    @click="handleNavTo"
  >
    <template v-for="item in menu">
      <template v-if="!item.meta.hidden">
        <a-menu-item :key="item.name" v-if="!item.children">
          <menu-title :icon="item.meta.icon" :title="item.meta.title"></menu-title>
        </a-menu-item>
        <menu-item v-else :key="item.key" :item="item" :titleClick="handleNavTo"/>
      </template>
    </template>
  </a-menu>
</template>

<script>
import MenuItem from "./Item";
import MenuTitle from "./Title";
import { mapGetters } from 'vuex'

export default {
  props: {
      menu: {
        type: Array,
        default: ()=>[]
      },
      mode: {
        type: String,
        default: 'inline'
      },
      theme: {
        type: String,
        default: 'light'
      }
  },
  components: {
    MenuItem,
    MenuTitle
  },
  data() {
    return {
      current: [],
      data: []
    };
  },
  computed: {
    ...mapGetters([
        'collapseSidebar'
    ]),
    collapse(){
      return this.collapseSidebar && this.mode == "inline" //inline模式下 collapse才可以使用
    }
  },
  watch: {
    $route(){
      this.getCurrent()
    }
  },
  created(){
    this.getCurrent()
  },
  methods: {
    getCurrent(){
      const routes = this.$route.matched;
      this.current =  [];
      routes.forEach(item=>{
        if(item.meta.notRefresh){
          const type = this.$route.params.type,
                curName = this.$route.name,
                idx = curName.lastIndexOf("_")+1;
          this.current.push(curName.substr(0, idx)+type)
        } else {
          this.current.push(item.name)
        }
      })
    },
    handleNavTo(item) {
      this.$router.push({ name: item.key });
    }
  },
};
</script>