import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/'
import Layout from '@/layout'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

import resultRouter from './modules/result'
import patentRouter from './modules/patent'
import searchRouter from './modules/search'
/**
 * path:      路径
 * name:      必填，路由需要 为避免命名冲突，建议增加前缀标识：patent_xx  result_xx  search_xx
 * component: Layout PageLayout
 * meta:
 *  title     菜单名称 String:必填
 *  icon      菜单图标 String:二级导航必填
 *  hidden    是否在导航上显示 Boolean:默认false
 *  keepAlive 是否缓存 Boolean:默认false------------TODO
 *  notRefresh是否保持导航栏选中状态 Boolean:默认false（用于详情页）
 */
export const constantRoutes = [
  {
    path: '/',
    name: 'index',
    component: Layout,
    redirect: '/home',
    children: [{
      path: '/home',
      name: 'home',
      meta: {
        title: '首页'
      },
      component: Home
    }]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: ()=>import("@/views/login")
  }
]

export const asyncRoutes = [
  ...searchRouter,
  ...patentRouter,
  ...resultRouter
]

const createRouter = () => new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes.concat(asyncRoutes)
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
