<template>
  <div class="app-container">
    <div class="app-header">
      <logo />
      <settings />
      <menubar :menu="menu" />
    </div>
    <div class="app-main" :class="objCls">
        <sidebar :menu="submenu" v-if="hasSidebar" />
        <div class="app-wrapper">
          <bread-crumb />
          <keep-alive>
            <router-view v-if="this.$route.meta.keepAlive"/>
          </keep-alive>
          <router-view v-if="!this.$route.meta.keepAlive"/>
        </div>
    </div>
  </div>
</template>

<script>
import { asyncRoutes } from "@/router";
import { Logo, Settings, Sidebar, BreadCrumb, Menubar } from './components'
import { mapGetters } from 'vuex'

export default {
  components: {
    Logo,
    Settings,
    Sidebar,
    BreadCrumb,
    Menubar
  },
  data(){
    return {
      menu: [],
      submenu: []
    }
  },
  watch: {
    $route(to, from){
      this.initPage()
    }
  },
  computed: {
    ...mapGetters([
      'collapseSidebar'
    ]),
    hasSidebar(){
      return this.submenu.length != 0
    },
    objCls(){
      return {
        'hasSidebar': this.hasSidebar,  //是否显示左侧导航
        'collapseSidebar': this.hasSidebar && this.collapseSidebar  //展开收起左侧导航
      }
    }
  },
  created(){
    asyncRoutes.forEach(item=>{
      this.menu.push({
        name: item.name,
        meta: item.meta
      })
    })
    this.initPage();
  },
  methods: {
    initPage(){
      let rootName = this.$route.path.match(/^\/[^/]+/)[0].substr(1); //匹配根
      this.submenu = rootName == 'home'? []:asyncRoutes.find(item=>item.name==rootName).children
    }
  }
};
</script>