<template functional>
  <a-sub-menu :key="props.item.name" v-on:titleClick="props.titleClick">
    <span slot="title">
      <menu-title :icon="props.item.meta.icon" :title="props.item.meta.title"></menu-title>
    </span>
    <template v-for="item in props.item.children">
      <template v-if="!item.meta.hidden">
        <a-menu-item v-if="!item.children" :key="item.name">
          <menu-title :icon="item.meta.icon" :title="item.meta.title"></menu-title>
        </a-menu-item>
        <menu-item v-else :key="item.name" :item="item" :titleClick="props.titleClick"/>
      </template>
    </template>
  </a-sub-menu>
</template>

<script>
import MenuTitle from './Title'

export default {
  components: {
    MenuTitle
  }
}
</script>