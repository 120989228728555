<script>
export default {
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []

    if (icon) {
      vnodes.push(<a-icon type={icon} />)
    }
    if (title) {
      vnodes.push(<span>{(title)}</span>)
    }

    return vnodes
  }
}
</script>
