<template>
  <div class="search-container">
    <div class="leftSide" v-if="$slots.left">
      <slot name="left"></slot>
    </div>
     <div class="rightMain">
       <div class="toolbar" v-if="$slots.toolbar||showBack">
         <a-button type="primary" @click="handleBack" v-if="showBack">返回</a-button>
         <slot name="toolbar"></slot>
       </div>
       <div class="searchbar" v-if="$slots.searchbar">
          <slot name="searchbar"></slot>
        </div>
       <div class="maincontent">
        <slot />
      </div>
     </div>
  </div>
</template>
<script>
  export default{
    props: {
      showBack: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleBack(){
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
      }
    }
  }
</script>