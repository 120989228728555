import Layout from '@/layout'
import PageLayout from '@/layout/PageLayout'

//提示：为避免命名冲突 name定义时建议以patent_ || p_ 标识
const router = [{
    path: '/patentScreen',
    name: 'patentScreen',
    component: Layout,
    meta: {
        title: '智能筛选工具',
        hidden:true
    },
    redirect: {name: 'ps_manage'},
    children: [{
        path: 'manage',
        name: 'ps_manage',
        meta: {
            title: '专利管理',
            icon: 'appstore'
        },
        component: PageLayout,
        redirect: {name: 'ps_manage_all'},
        children:[{
            path: 'all',
            name: 'ps_manage_all',
            meta: {
                title: '全部专利',
                hidden: true
            },
            component: ()=>import('@/views/patent/screen/manage')
        },{
            path: 'subject',
            name: 'ps_manage_subject',
            meta: {
                title: '专利学科分类'
            },
            component: ()=>import('@/views/patent/screen/manage')
        },{
            path: 'device',
            name: 'ps_manage_device',
            meta: {
                title: '专利设备分类'
            },
            component: ()=>import('@/views/patent/screen/manage')
        },{
            path: '/patentScreen/manage/:type/detail/:id',
            name: 'ps_manage_detail',
            meta: {
                title: '详情',
                hidden: true
            },
            component: ()=>import('@/views/patent/screen/manage/detail')
        }]
    },{
        path: 'review',
        name: 'ps_review',
        meta: {
            title: '在线评审',
            icon: 'edit'
        },
        component: PageLayout,
        redirect: {name: 'ps_review_all'},
        children:[{
            path: 'all',
            name: 'ps_review_all',
            meta: {
                title: '全部专利',
                hidden: true
            },
            component: ()=>import('@/views/patent/screen/review')
        },{
            path: 'fm',
            name: 'ps_review_fm',
            meta: {
                title: '发明列表'
            },
            component: ()=>import('@/views/patent/screen/review')
        },{
            path: 'syxx',
            name: 'ps_review_syxx',
            meta: {
                title: '实用新型列表'
            },
            component: ()=>import('@/views/patent/screen/review')
        },{
            path: 'wgsj',
            name: 'ps_review_wgsj',
            meta: {
                title: '外观设计列表'
            },
            component: ()=>import('@/views/patent/screen/review')
        }]
    },{
        path: 'summary',
        name: 'ps_summary',
        meta: {
            title: '评审汇总',
            icon: 'bars'
        },
        component: ()=>import('@/views/patent/screen/summary')
    },{
        path: 'indicator',
        name: 'ps_indicator',
        meta: {
            title: '指标管理',
            icon: 'tags'
        },
        component: ()=>import('@/views/patent/screen/indicator')
    },{
        path: 'grade',
        name: 'ps_grade',
        meta: {
            title: '分级管理',
            icon: 'align-left'
        },
        component: PageLayout,
        redirect: {name: 'ps_grade_guid'},
        children:[{
            path: 'guid',
            name: 'ps_grade_guid',
            meta: {
                title: '全部专利',
                hidden: true
            },
            component: ()=>import('@/views/patent/screen/grade/guid')
        },{
            path: 'unit',
            name: 'ps_grade_unit',
            meta: {
                title: '单位分类分级'
            },
            component: ()=>import('@/views/patent/screen/grade')
        },{
            path: 'tech',
            name: 'ps_grade_tech',
            meta: {
                title: '技术分类分级'
            },
            component: ()=>import('@/views/patent/screen/grade')
        },{
            path: '/patentScreen/grade/:type/detail/:id',
            name: 'ps_grade_detail',
            meta: {
                title: '详情',
                hidden: true
            },
            component: ()=>import('@/views/patent/screen/grade/detail')
        }]
    },{
        path: 'expert',
        name: 'ps_expert',
        meta: {
            title: '专家管理',
            icon: 'team'
        },
        component: ()=>import('@/views/patent/expert')
    }]
},{
    path: '/patentBreed',
    name: 'patentBreed',
    component: Layout,
    meta: {
        title: '辅助培育工具',
        hidden:true
    },
    redirect: {name: 'pb_manage'},
    children: [{
        path: 'manage',
        name: 'pb_manage',
        meta: {
            title: '专利管理',
            icon: 'appstore'
        },
        component: ()=>import('@/views/patent/breed/manage')
    },{
        path: 'review',
        name: 'pb_review',
        meta: {
            title: '在线评审',
            icon: 'edit'
        },
        component: ()=>import('@/views/patent/breed/review')
    },{
        path: 'summary',
        name: 'pb_summary',
        meta: {
            title: '评审汇总',
            icon: 'bars'
        },
        component: ()=>import('@/views/patent/breed/summary')
    },{
        path: 'indicator',
        name: 'pb_indicator',
        meta: {
            title: '指标管理',
            icon: 'tags'
        },
        component: ()=>import('@/views/patent/breed/indicator')
    },{
        path: 'grade',
        name: 'pb_grade',
        meta: {
            title: '分级管理',
            icon: 'align-left'
        },
        component: ()=>import('@/views/patent/breed/grade')
    },{
        path: 'files',
        name: 'pb_files',
        meta: {
            title: '模板文件库',
            icon: 'folder'
        },
        component: ()=>import('@/views/patent/breed/files')
    },{
        path: 'award',
        name: 'pb_award',
        meta: {
            title: '专利奖管理',
            icon: 'money-collect'
        },
        component: ()=>import('@/views/patent/breed/award')
    },{
        path: 'expert',
        name: 'pb_expert',
        meta: {
            title: '专家管理',
            icon: 'team'
        },
        component: ()=>import('@/views/patent/expert')
    }]
}]

export default router