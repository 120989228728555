import Vue from 'vue'

import Navbar from '@/components/common/Navbar'
Vue.component('Navbar', Navbar)


/**
 * 自定义组件命名规范：参考patent示例
 * search(专利检索部分) 以S开头
 * patent(专利筛选部分) 以P开头
 * result(科技成果部分) 以R开头
 */
//search相关
import SContainer from '@/components/search/SContainer'
Vue.component('SContainer', SContainer)



//patent相关
import PContainer from '@/components/patent/PContainer'
Vue.component('PContainer', PContainer)




//result相关
import RContainer from '@/components/result/RContainer'
Vue.component('RContainer', RContainer)

