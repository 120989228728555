<template>
  <router-link to="/" class="app-logo">
<!--    <img src="@/assets/img/bigdata1.png" :alt="title" class="img" />-->
    <span class="text">
      {{ title }}
    </span>
  </router-link>
</template>

<script>
import { siteTitle } from "@/settings";
export default {
  computed: {
    title() {
      return siteTitle || "";
    },
  },
};
</script>