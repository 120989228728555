<template>
  <a-breadcrumb v-if="breadList.length>0" class="app-breadcrumb">
      <a-breadcrumb-item>
        <router-link to="/"><a-icon type="home" /></router-link>
      </a-breadcrumb-item>
      <template v-for="(item,key) in breadList">
        <a-breadcrumb-item v-if="key==breadList.length-1" :key="item.name">
          {{ item.meta.title }}
        </a-breadcrumb-item>
        <a-breadcrumb-item v-else :key="item.name">
          <router-link :to="item.path">{{ item.meta.title }}</router-link>
        </a-breadcrumb-item>
      </template>
  </a-breadcrumb>
</template>

<script>
export default {
  data(){
    return {
      breadList: []
    }
  },
  watch: {
    $route(to, from){
      this.initPage()
    }
  },
  mounted(){
    this.initPage();
  },
  methods: {
    initPage(){
      this.breadList = [];
      this.$route.matched.forEach(item=>{
        //排除首页和无效地址
        if(item.path && item.name != 'home'){
          this.breadList.push(item);
        }
      })
    }
  }
}
</script>